exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-complete-login-js": () => import("./../../../src/pages/completeLogin.js" /* webpackChunkName: "component---src-pages-complete-login-js" */),
  "component---src-pages-discount-[code]-js": () => import("./../../../src/pages/discount/[code].js" /* webpackChunkName: "component---src-pages-discount-[code]-js" */),
  "component---src-pages-download-app-js": () => import("./../../../src/pages/download-app.js" /* webpackChunkName: "component---src-pages-download-app-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my/account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-my-addresses-js": () => import("./../../../src/pages/my/addresses.js" /* webpackChunkName: "component---src-pages-my-addresses-js" */),
  "component---src-pages-my-orders-[order]-js": () => import("./../../../src/pages/my/orders/[order].js" /* webpackChunkName: "component---src-pages-my-orders-[order]-js" */),
  "component---src-pages-my-orders-js": () => import("./../../../src/pages/my/orders.js" /* webpackChunkName: "component---src-pages-my-orders-js" */),
  "component---src-pages-rewards-js": () => import("./../../../src/pages/rewards.js" /* webpackChunkName: "component---src-pages-rewards-js" */),
  "component---src-pages-search-[name]-tsx": () => import("./../../../src/pages/search/[name].tsx" /* webpackChunkName: "component---src-pages-search-[name]-tsx" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-collections-tsx": () => import("./../../../src/templates/collections.tsx" /* webpackChunkName: "component---src-templates-collections-tsx" */),
  "component---src-templates-login-b-2-b-js": () => import("./../../../src/templates/loginB2b.js" /* webpackChunkName: "component---src-templates-login-b-2-b-js" */),
  "component---src-templates-navigation-template-js": () => import("./../../../src/templates/NavigationTemplate.js" /* webpackChunkName: "component---src-templates-navigation-template-js" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-signup-b-2-b-js": () => import("./../../../src/templates/signupB2B.js" /* webpackChunkName: "component---src-templates-signup-b-2-b-js" */)
}

