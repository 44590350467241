import React, {useContext} from "react";
import Client from "shopify-buy";
import axios from "axios";
import {LocalCartContext} from "./LocalCartProvider";
import {addItemToCart, beginCheckout, removeItemFromCart} from "../services/eventDispatcher";
import _ from "lodash";
import {navigate} from "gatsby";
import { SiteAuthContext } from "./SiteAuthProvider"
import { IS_B2B } from "../utils/config/public" //todo import only debounce


export const ShopifyCartContext = React.createContext({});


const isBrowser = typeof window !== `undefined`
const localCartKey = `shopify_cart_id`
const client = Client.buildClient(
    {
        domain: process.env.GATSBY_SHOPIFY_STORE_URL,
        storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
    },
    fetch
)

const ShopifyCartProvider = ({children}) => {
    const [cart, setCart] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [bouncing, setBouncing] = React.useState(false)
    const {products} = useContext(LocalCartContext);
    const {user} = useContext(SiteAuthContext)
    const {loggingStatus} = useContext(SiteAuthContext)

    function tagBeginCheckout() {

        const gaItems = products.map((product) => {
            const taxonomy = product.sourcePath ? product.sourcePath.split("/") : []


            return {
                item_id: product.sku,
                item_name: product.title,
                affiliation: "Casinetto",
                currency: "AED",
                discount: !product.prevPrice ? 0 : Number(Math.abs(product.prevPrice - product.price).toFixed(2)),
                index: 0,
                item_brand: product.vendor,
                price: !product.prevPrice ? Number(product.price) : Number(product.prevPrice),
                quantity: product.quantity,

                item_list_id: product.list_id || "",
                item_list_name: product.list_name || "",
                item_category: taxonomy[0] || "",
                item_category2: taxonomy[1] || "",
                item_category3: taxonomy[2] || "",
                item_category4: taxonomy[3] || "",
                item_category5: taxonomy[4] || "",

            }
        })
        beginCheckout(gaItems);
    }

    React.useEffect(() => {
        const init = async () => {
            setLoading(true)

            // console.log("starting initialization")
            const existingCartID = localStorage.getItem(localCartKey)
            // console.log("existing cart id", existingCartID)

            const serverCartResponse = await fetch(`/api/cart`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({localCartId:existingCartID}),
            })
            const cartResponse = await serverCartResponse.json()
            // console.log("server cart",cartResponse)
            const cart = cartResponse.cart

            if(cart.buyerIdentity && (cart.buyerIdentity.email || cart.buyerIdentity.customer)){
                // console.log("Cart for user we do not store locally and we remove local carts", {user,cart})
                localStorage.removeItem(localCartKey)
            } else{
                // console.log("Cart for anonymous user we store locally", {user,cart})
                localStorage.setItem(localCartKey, cart.id.split("/").pop())
            }
            setCart(cart)
        }

        if (isBrowser) {
            console.log("effect on variables", {user,loggingStatus})
            if (loggingStatus === "LOGGED_OUT") {
                console.log("cart init", {user,loggingStatus})
                init().then(() => {
                    setLoading(false)
                }).catch(() =>
                    setLoading(false)
                );
            }
        }
    }, [user,loggingStatus])

    const addDiscountToCart = async (checkout, discountCode) => {
        console.log("adding discount to cart", discountCode)
        await client.checkout.addDiscount(checkout.id, discountCode)
        console.log("added discount to cart", discountCode)
    }

    const checkoutCart = async () => {
        setLoading(true)
        tagBeginCheckout()
        const cartUrl = `${cart.checkoutUrl.replace("//casinetto.com", "//checkout.casinetto.com").replace("//portal.horeca.casinetto.com", "//checkout.portal.horeca.casinetto.com").replace("//portal.horeca.casinetto.sa", "//checkout.portal.horeca.casinetto.sa")}?logged_in=true`
        console.log("cart url",cartUrl)
        if(IS_B2B){
            console.log("b2b checkout", {user, cart, cartUrl})
            window.location.href = cartUrl
        }else{
            console.log("checkout b2c", user, cart,cartUrl)
            //todo replace with fetch
            if (user && user.emailVerified) {
                const pass = await axios.post(`/api/multipass`, {"cartUrl": cartUrl})
                const checkoutUrl = pass.data.url.replace("//casinetto.myshopify.com", "//checkout.casinetto.com").replace("//casinetto-horeca.myshopify.com", "//checkout.portal.horeca.casinetto.com").replace("//portal.horeca.casinetto.sa", "//checkout.portal.horeca.casinetto.sa")
                console.log("checkout url",checkoutUrl)
                window.location.href = checkoutUrl
            } else {
                // window.location.href = cart.checkoutUrl.replace("casinetto.com", "checkout.casinetto.com")
                await navigate("/login?action=cart-checkout")
                setLoading(false)
            }
        }
    }

const addAllToCart = async (items, algoliaQuery) => {
    setBouncing(true)
    const id = cart?.id.split("/").pop()
    //console.log("adding to cart ",id)

    const linesToAddDirty = items.map(item => {
        if (item !== null) {
            let attributes = []
            if (item.gelato_flavours) {
                const flavours = item.gelato_flavours.map(fl => fl.name).join(", ")
                attributes.push({"key": "Flavours", "value": flavours})
            }

            const cat = item.source_path ? item.source_path.split("/") : []
            attributes.push({"key": "__ga_list_name", "value": item.list_name || "na"})
            attributes.push({"key": "__ga_list_id", "value": item.list_id || "na"})
            attributes.push({"key": "__ga_list_index", "value": item.list_index?.toString() || "0"})
            cat[0] && attributes.push({"key": "__ga_cat", "value": cat[0]})
            cat[1] && attributes.push({"key": "__ga_cat1", "value": cat[1]})
            cat[2] && attributes.push({"key": "__ga_cat2", "value": cat[2]})


            tagAddItemToCart(item, algoliaQuery)
            return {
                "attributes": attributes,
                "merchandiseId": `gid://shopify/ProductVariant/${item.variantId}`,
                "quantity": 1
            }
        }
    })

    const linesToAdd = linesToAddDirty.filter((value) => value != null);

    fakeAddToCart(items)

    const response = await fetch(`/api/cart/${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "lines": linesToAdd
        })
    })
    setBouncing(false)
    const json = await response.json()

    setCart(json.cart)
}

const addToCart = async (item, algoliaQuery) => {
    await addAllToCart([item], algoliaQuery)
}

const fakeAddToCart = (items) => {

    const fakeCart = {
        id: cart.id,
        lines: {
            edges: cart.lines.edges
        }
    }

    items.forEach(item => {
            if (item !== null) {


                fakeCart.lines.edges.push({
                    node: {
                        id: `gid://shopify/cartLine/${item.variantId}`,
                        attributes: [],
                        cost: {
                            totalAmount: {
                                amount: Number(item.price),
                                currencyCode: "AED"
                            },
                            compareAtAmountPerQuantity: {
                                amount: 0,
                                currencyCode: "AED"
                            },
                            amountPerQuantity: {
                                amount: Number(item.price),
                                currencyCode: "AED"
                            }
                        },
                        merchandise: {
                            id: `gid://shopify/ProductVariant/${item.variantId}`,
                            sku: "test",
                            product: {
                                title: item.title
                            }
                        },
                        quantity: 1
                    }
                })
            }
        }
    )

    setCart(fakeCart)
}

const addToCartLive = React.useRef(_.debounce(async (id, item, quantity) => {
    console.log("test debounce", quantity)
    setBouncing(true)
    const response = await fetch(`/api/cart/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "lines": [
                {
                    "id": item.node.id,
                    "attributes": item.node.attributes,
                    "merchandiseId": item.node.merchandise.id,
                    "quantity": quantity
                }
            ]
        })
    })
    const json = await response.json()
    console.log("cart",json)
    setCart(json.cart)
    setBouncing(false)
    if (quantity === 0) {
        tagRemoveItemFromCart(item, 0)
    }
}, 400, {})).current

const updateCart = async (item, quantity) => {
    console.log("test update cart", item, quantity)

    const id = cart?.id.split("/").pop()
    fakeUpdateCart(item, quantity)
    addToCartLive(id, item, quantity)
}

const fakeUpdateCart = (item, quantity) => {

    console.log("fake update cart", {item, quantity})


    const fakeCart = {
        id: cart.id,
        lines: {
            edges: cart.lines.edges
        }
    }

    const lineIndex = fakeCart.lines.edges.findIndex(node => node.node.id === item.node.id)

    if (quantity === 0) {
        fakeCart.lines.edges.splice(lineIndex, 1); // remove 1 element at index 2 (value: 3)

    } else {
        fakeCart.lines.edges[lineIndex].node.quantity = quantity
    }

    setCart(fakeCart)
}


function tagAddItemToCart(item, algoliaQuery) {
    addItemToCart(
        item.sku,
        item.variantId,
        item.title,
        !item.prevPrice ? Number(item.price) : Number(item.prevPrice),
        !item.prevPrice ? 0 : Number(Math.abs(item.prevPrice - item.price).toFixed(2)),
        item.vendor,
        item.quantity_available,
        item.list_index,
        item.list_id,
        item.list_name,
        item.source_path,
        algoliaQuery
    );
}

function tagRemoveItemFromCart(item, quantity) {
    removeItemFromCart(
        item.node.merchandise.sku,
        item.node.merchandise.product.title,
        item.node.cost.compareAtAmountPerQuantity ? Number(item.node.cost.compareAtAmountPerQuantity.amount) : Number(item.node.cost.totalAmount.amount),
        item.node.cost.compareAtAmountPerQuantity ? Math.abs(Number(item.node.cost.compareAtAmountPerQuantity.amount) - Number(item.node.cost.totalAmount.amount).toFixed(2)) : 0,
        item.vendor,
        quantity);
}

const productInCart = (variantId) => {
    if (cart) {
        const index = cart.lines.edges.findIndex(
            line => line.node.merchandise.id === variantId
        );
        return cart.lines.edges[index]
    } else {
        return null
    }
}

const countGelatoAggregates = (lineItem) => {
    const variantId = lineItem?.node?.merchandise.id
    if (cart) {
        return cart.lines.edges.reduce((total, line) => {
            if (variantId === line.node.merchandise.id)
                return total + line.node.quantity;
            else
                return total;
        }, 0)
    } else {
        return 0
    }
}


return (
    <ShopifyCartContext.Provider value={{
        checkoutCart,
        loading,
        addDiscountToCart,

        cart,
        addToCart,
        addAllToCart,
        updateCart,
        productInCart,
        countGelatoAggregates,
        bouncing
    }}>
        {children}
    </ShopifyCartContext.Provider>
)
}

export default ShopifyCartProvider;
