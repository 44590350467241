import React, { useContext, useState } from "react"
import { FirebaseContext } from "./FirebaseProvider"
import { B2bAuthContext } from "./B2BAuthProvider"
import { IS_B2B } from "../utils/config/public"

export const SiteAuthContext = React.createContext({})

export const isBrowser = () => typeof window !== "undefined"

const SiteAuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loggingStatus, setLoggingStatus] = useState("LOGGED_OUT")
  const isB2b = IS_B2B
  const firebaseContext = useContext(FirebaseContext)
  const b2bAuthContext = useContext(B2bAuthContext)


  const signOut = () => {
    if(isB2b){
      // console.log("b2b signout")
      b2bAuthContext.signOut()
    }else{
      // console.log("b2c signout")
      firebaseContext.signOut()
    }
  }

  React.useEffect(() => {
    console.log("SiteAuthProvider", { firebaseContext, b2bAuthContext })
    if (isB2b) {
      setUser(b2bAuthContext.user)
    } else {
      setUser(firebaseContext.user)
    }

    if(user && loggingStatus === "LOGGED_OUT"){
      console.log("cart setting logging status to LOGGED_IN",user)
      setLoggingStatus("LOGGED_IN")
    }else if(!user && loggingStatus === "LOGGED_IN"){
      console.log("cart setting logging status to LOGGED_OUT",user)
      setLoggingStatus("LOGGED_OUT")
    }
  }, [firebaseContext.user, b2bAuthContext.user,user])


  return (
    <SiteAuthContext.Provider value={{
      user,
      isB2b,
      signOut,
      loggingStatus
    }}>
      {children}
    </SiteAuthContext.Provider>
  )
}

export default SiteAuthProvider
